<template>
  <div style="padding:0 10px;">
    <p>只可查询2023年9月以后的数据。<router-link :to="{name: 'share.check.total'}">汇总数据点此查看</router-link></p>
      <div>
        <!----- 搜索框 start------->
        <el-form :inline="true" class="search">
          <el-form-item prop="date">
            <el-date-picker
                v-model="searchData.publish_at"
                type="daterange"
                :editable="false"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item>
            <el-button @click="getData()">查询</el-button>
          </el-form-item>
        </el-form>
        <!----- 搜索框 end------->
      </div>

      <el-empty v-show="!tableData.length" description="当前日期没有数据" />

      <el-row :gutter="5" v-show="tableData.length">
        <el-col style="margin-bottom: 5px;" :span="6" :lg="6" :xs="12" :xl="4" v-for="(item, index) in tableData" :key="index">
          <el-card class="box-card">
            <template #header>
              <b v-text="item.name"></b>
              <br>
            </template>
            <div>
              <el-descriptions :title="date[0] + '~' + date[1]" column="1">
                <el-descriptions-item label="项目">{{ item.project }}</el-descriptions-item>
                <el-descriptions-item label="单价">{{ item.price }}</el-descriptions-item>
                <el-descriptions-item label="券数">{{ item.total_coupon }}</el-descriptions-item>
                <el-descriptions-item label="单数">{{ item.total_order }}</el-descriptions-item>
                <el-descriptions-item label="金额">{{ item.total_price }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import {apiGetCheckData} from '@/api/shareWechatTaskApi'
export default {
  data() {
    return {
      searchData: {
        publish_at: []
      },
      tableData: [],
      date: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData () {
      apiGetCheckData(this.searchData).then(data => {
        this.tableData = data.data
        this.date = data.date
      })
    }
  }
}
</script>

<style scoped>

.el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
  padding-bottom: 0 !important;
}
</style>
