import {httpGet, httpPost} from '@/plugins/request'

/**
 * 获取核销计划列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetWechatCheckTask(query = []) {
    return httpGet('/share/wechat', query)
}

/**
 * 获取核销计划的详情
 *
 * @param code
 * @returns {Promise<*>}
 */
export function apiGetWechatCheckTaskInfo(code) {
    return httpGet(`/share/wechat/${code}/info`)
}

/**
 *上传核销计划图片
 *
 * @returns {Promise<*>}
 */
export function apiUploadWechatCheckTask(data)
{
    return httpPost('/share/wechat/upload', data)
}

/**
 * 获取刷单数据
 *
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetCheckData (query = []) {
    return httpGet('/share/data/check', query)
}

/**
 * 获取刷单汇总数据
 *
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetCheckTotalData (query = []) {
    return httpGet('/share/data/check/task', query)
}
